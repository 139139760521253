<!-- 文本输入框 -->
<template>
  <div
    v-if="selectId !== ''"
    class="text"
    id="text"
  >
    <div class="emoji">
      <i
        class="icon iconfont icon-look"
        @click="showEmoji=!showEmoji"
      ></i>
      <i
        class="click-icon"
        @click="sendClick"
      ></i>
      <input
        id="upload_file"
        type="file"
        accept=".jpeg,.jpg,.png,.gif"
        multiple
        class="upload-file"
        @change="fileChange($event)"
      />
      <input
        id="upload_file2"
        type="file"
        accept=".mp4"
        multiple="false"
        class="upload-video"
        @change="videoChange($event)"
      />
      <transition name="showbox">
        <div
          v-show="showEmoji"
          class="emojiBox"
        >
          <li v-for="(item, index) in emojis" :key="index">
            <img v-if="item.title !== '戳一戳'" :src="'static/emoji/' + item.file" :data="item.code"
              @click="addEmoji(item.code)">
          </li>
          <!-- <li
            v-for="(item, index) in emojis"
            :key="index"
          >
            <img
              v-if="item.title !== '戳一戳'"
              :src="'static/emoji/'+item.file"
              :data="item.code"
              @click="content +=item.code"
            >
          </li> -->
        </div>
      </transition>
    </div>
    <!-- <textarea
      ref="text"
      v-model="content"
      @keyup="onKeyup"
      @click="showEmoji=false"
    ></textarea> -->
    <div class="box">
      <div ref="text" id="preview" class="preview" style="height: auto;min-height:120px;max-height: 120px; max-width: 1000px;overflow: hidden;" v-on:paste="handlePaste"
        v-on:keyup="onKeyup" v-on:keydown="onkeyDown" v-on:click="closeMask" contenteditable='true'
        @input="content = $event.target.innerHTML">
      </div>
    </div>
    <div
      class="send"
      @click.stop.prevent="send"
    >
      <span>发送(ent)</span>
    </div>
    <div style=""></div>
    <transition name="appear">
      <div
        v-show="warn"
        class="warn"
      >
        <div class="description">不能发送空白信息</div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
const { Parser } = require('htmlparser2')
import { textMsg, upload, setListTime } from '@/api'
export default {
  data () {
    return {
      content: '',
      reply: '未找到',
      frequency: 0,
      warn: false,
      showEmoji: false
    };
  },
  computed: {
    ...mapState([
      'selectId',
      'emojis'
    ]),
    ...mapGetters([
      'selectedChat'
    ])
  },
  watch: {
    // 在选择其它对话的时候 聚焦输入框
    selectId () {
      setTimeout(() => {
        // this.$refs.text.focus()
      }, 0)
      this.$nextTick(() => {
        const _this = this
        this.$refs.text.focus()
        const box = document.getElementById('text')
        document.ondragover = () => false
        document.ondrop = () => false
        box.ondrop = (e) => {
          const files = e.dataTransfer.files
          if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
              let url = window.URL || window.webkitURL
              let img = new Image();
              let item = files[i]
              img.src = url.createObjectURL(item)

              img.onload = function () {
                console.log(item);
                upload({
                  list_id: _this.selectedChat.list_id,
                  _token: localStorage.getItem('token'),
                  file: item
                }).then(res => {
                  if (res.err == 0) {
                    let msg = {
                      content: {
                        url: res.data.save_name,
                        h: this.height,
                        w: this.width,
                        save_pic_path: ''
                      },
                      type: 2
                    }
                    textMsg({
                      list_id: _this.selectedChat.list_id,
                      _token: localStorage.getItem('token'),
                      content_type: 0,
                      content: JSON.stringify({
                        url: res.data.save_name,
                        h: this.height,
                        w: this.width,
                        save_pic_path: '',
                        type: 2
                      }),
                      _agent_id: 1
                    }).then(res => {
                      _this.$store.dispatch('selectSession', _this.selectId)
                    })
                    setListTime({
                      list_id: _this.selectedChat.list_id,
                      _token: localStorage.getItem('token'),
                      _agent_id: 1
                    })
                    _this.$store.dispatch('sendMessage', msg)
                  }

                })
              }
            }
          }
        }
      })
    },
    // 当输入框中的值为空时 弹出提示  并在一秒后消失
    content () {
      if (this.content === '') {
        if (this.frequency === 0) {
          this.warn = true;
          this.frequency++
          setTimeout(() => {
            this.warn = false;
          }, 1000)
        }
      }
    }
  },
  // 在进入的时候 聚焦输入框
  mounted () {
    setTimeout(function(){
		 var box=document.getElementById('preview');
		 
		 box.ondragover=function (e){
		 
		  e.preventDefault();
		 
		 }
		 
		 box.ondrop=function (e){
		 
		  e.preventDefault();
		 
		  // console.log(e.dataTransfer.files[0]);
		 
		  var f=e.dataTransfer.files[0];//获取到第一个上传的文件对象
		 
		  var fr=new FileReader();//实例FileReader对象
		 
		  fr.readAsDataURL(f);//把上传的文件对象转换成url
		 
		  fr.onload=function (e){
		 
		   console.log(e);
		 
		   // var Url=e.target.result;//上传文件的URL
		 
		   var Url=this.result;//上传文件的URL
		 
		   box.innerHTML+='<img src="'+Url+'" alt="">';
		 
		  }
		 
		 }
	 },2000)
  },
  methods: {
    // 按回车发送信息
    onKeyup (e) {
      if (e.key === 'Control') {
        this.flagCtrl = false;
      }
      if (e.keyCode === 13 && this.flagCtrl) {
        this.content = this.content + '\n';
      }
      if (e.keyCode === 13 && !this.flagCtrl) {
        this.send()
      }
    },
    onkeyDown(e) {
      // console.log('监听键盘按下---'+this.content);
      if (e.key === 'Control') {
        this.flagCtrl = true;
      }
    },
    // 消息是否是图片
    isImage(con) {
      // 如果是图片，则转换成图片
      // let reg =
      //   /^https?:\/\/(.+\/)+.+(\.(gif|png|jpg|jpeg|webp|svg|psd|bmp|tif))$/i;
      let reg = RegExp(/data:image\/.*;base64,/)
      if (reg.test(con)) {
        return true;
      } else {
        return false;
      }
    },
    addEmoji(code) {
      preview.innerHTML += code
      console.log(code)
    },
    parseHtml(html) {
      let _this = this;
      const parser = new Parser({
        onreset() {
          // console.info('reset');
        },
        onopentag(name, attribs) {
          // console.info('opentag', name);
          // console.info('attribs', attribs);
          let content = attribs.src;
          if (_this.isImage(content)) {
            // 如果是base64图片
            _this.uploadBaseFile(content);
          } else {
            // 如果是网络图片
            console.log(this.content); return;
            textMsg({
              list_id: _this.selectedChat.list_id,
              _token: localStorage.getItem('token'),
              content_type: 0,
              content: JSON.stringify({
                text: content
              }),
              _agent_id: 1
            }).then(res => {
              _this.content = '';
              _this.$store.dispatch('selectSession', _this.selectId)
            })
            setListTime({
              list_id: _this.selectedChat.list_id,
              _token: localStorage.getItem('token'),
              _agent_id: 1
            })
            _this.content = ''
          }

          _this.content = ``;
        },
        ontext(text) {
          console.info('text', text);
          // _this.send()
        },
        onclosetag(tagname) {
          console.info('closetag', tagname)
        },
        onopentagname(name) {
          console.info('opentagname', name)
        },
        onend() {
          console.info('onend');
        },
        oncomment(val) {
          console.info('comment', val);
        },
        oncommentend() {
          console.info('commentend');
        },
        oncdatastart() {
          console.info('oncdatastart');
        },
        oncdataend() {
          console.info('oncdataend');
        },
        onprocessinginstruction(name, data) {
          console.info('onprocessinginstruction', name);
          console.info('onprocessinginstruction', data);
        }
      }, {
        recognizeCDATA: true
      })
      parser.parseComplete(html);
    },
    random() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16))
    },
    // 上传base64图片
    base64toFile(base64Data, filename) {
      var arr = base64Data.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, {
        type: mime
      })
    },
    uploadBaseFile(base64) {
      let baseFile = this.base64toFile(base64, this.random() + '.png');
      let e = [baseFile];
      let _this = this;
      let len = 1;

      for (var i = 0; i < len; i++) {
        let url = window.URL || window.webkitURL
        let img = new Image();
        let item = baseFile;
        img.src = url.createObjectURL(item)
        img.onload = function () {
          console.log(item);
          upload({
            list_id: _this.selectedChat.list_id,
            _token: localStorage.getItem('token'),
            file: item
          }).then(res => {
            if (res.err == 0) {
              let msg = {
                content: {
                  url: res.data.save_name,
                  h: this.height,
                  w: this.width,
                  save_pic_path: ''
                },
                type: 2
              }
              // console.log(res.data.save_name);return;
              textMsg({
                list_id: _this.selectedChat.list_id,
                _token: localStorage.getItem('token'),
                content_type: 2,
                content: JSON.stringify({
                  url: res.data.save_name,
                  h: this.height,
                  w: this.width,
                  save_pic_path: '',
                  type: 2
                }),
                _agent_id: 1
              }).then(res => {
                _this.content = '';
                _this.$store.dispatch('selectSession', _this.selectId)
              })
              setListTime({
                list_id: _this.selectedChat.list_id,
                _token: localStorage.getItem('token'),
                _agent_id: 1
              })


              _this.$store.dispatch('sendMessage', msg)
            }

          })
        }
      }
    },
    // 点击发送按钮发送信息
    send () {
      this.closeMask();
      this.content = preview.innerHTML
      this.content = this.content.replace("<div><br></div>", "")
      preview.innerHTML = ''
      this.closeMask();
      if (this.content.length < 1) {
        this.warn = true
        this.content = ''
        setTimeout(() => {
          this.warn = false;
        }, 1000)
      } else {
        var msg = {
          content: this.content,
          type: 0
        }
        if (this.content.indexOf('<img') > -1) {
          this.parseHtml(this.content);
          return
        }
        // this.$store.dispatch('sendMessage', msg)
        textMsg({
          list_id: this.selectedChat.list_id,
          _token: localStorage.getItem('token'),
          content_type: 0,
          content: JSON.stringify({
            text: this.content
          }),
          _agent_id: 1
        }).then(res => {
          this.content = ''
          this.$store.dispatch('selectSession', this.selectId)
        })
        setListTime({
          list_id: this.selectedChat.list_id,
          _token: localStorage.getItem('token'),
          _agent_id: 1
        })
        this.content = ''
      }
    },
    
    // 关闭弹窗
    closeMask() {
      this.showEmoji = false;
      // this.show_concat = false;
      // this.show_friend = false;
      // this.show_group = false;
    },
    sendClick () {
      this.closeMask();
      var msg = {
        content: '[戳一戳]',
        type: 0
      }
      this.$store.dispatch('sendMessage', msg)
      textMsg({
        list_id: this.selectedChat.list_id,
        _token: localStorage.getItem('token'),
        content_type: 0,
        content: JSON.stringify({
          text: '[戳一戳]'
        }),
        msgItem_type: 0,
        _agent_id: 1
      })
      setListTime({
        list_id: this.selectedChat.list_id,
        _token: localStorage.getItem('token'),
        _agent_id: 1
      })
    },
    fileChange (e) {
      this.closeMask();
      let _this = this
      let len = e.target.files.length;

      for (var i = 0; i < len; i++) {
        let url = window.URL || window.webkitURL
        let img = new Image();
        let item = e.target.files[i]
        img.src = url.createObjectURL(item)

        img.onload = function () {
          console.log(item);
          upload({
            list_id: _this.selectedChat.list_id,
            _token: localStorage.getItem('token'),
            file: item
          }).then(res => {
            if (res.err == 0) {
              let msg = {
                content: {
                  url: res.data.save_name,
                  h: this.height,
                  w: this.width,
                  save_pic_path: ''
                },
                type: 2
              }
              textMsg({
                list_id: _this.selectedChat.list_id,
                _token: localStorage.getItem('token'),
                content_type: 2,
                content: JSON.stringify({
                  url: res.data.save_name,
                  h: this.height,
                  w: this.width,
                  save_pic_path: '',
                  type: 2
                }),
                _agent_id: 1
              }).then(res => {
                _this.$store.dispatch('selectSession', _this.selectId)
              })
              setListTime({
                list_id: _this.selectedChat.list_id,
                _token: localStorage.getItem('token'),
                _agent_id: 1
              })
              _this.$store.dispatch('sendMessage', msg)
            }

          })
        }
      }

    },
    videoChange (e) {
      let _this = this
      let len = e.target.files.length;

      for (var i = 0; i < len; i++) {
        let url = window.URL || window.webkitURL
        let item = e.target.files[i]
          console.log(item);
          upload({
            list_id: _this.selectedChat.list_id,
            _token: localStorage.getItem('token'),
            file: item
          }).then(res => {
            if (res.err == 0) {
              let msg = {
                content: {
                  url: res.data.save_name,
                  h: this.height,
                  w: this.width,
                  save_pic_path: ''
                },
                type: 3
              }
              textMsg({
                list_id: _this.selectedChat.list_id,
                _token: localStorage.getItem('token'),
                content_type: 3,
                content: JSON.stringify({
                  url: res.data.save_name,
                  h: this.height,
                  w: this.width,
                  save_pic_path: '',
                  type: 3
                }),
                _agent_id: 1
              }).then(res => {
                _this.$store.dispatch('selectSession', _this.selectId)
              })
              setListTime({
                list_id: _this.selectedChat.list_id,
                _token: localStorage.getItem('token'),
                _agent_id: 1
              })
              _this.$store.dispatch('sendMessage', msg)
            }

          })
      }

    },
    
    // 监听粘贴操作
    handlePaste(event) {
      // console.log('监听粘贴---'+this.content);
      var _this = this;
      const items = (event.clipboardData || window.clipboardData).items;
      let file = null;

      if (!items || items.length === 0) {
        this.$message.error("当前浏览器不支持本地");
        return;
      }
      // 搜索剪切板items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") !== -1) {
          file = items[i].getAsFile();
          break;
        }
      }
      if (!file) {
        // this.$message.error("粘贴内容非图片");
        return;
      }
      // 此时file就是我们的剪切板中的图片对象
      // 如果需要预览，可以执行下面代码
      const reader = new FileReader();
      reader.onload = event => {

        preview.innerHTML = `<img style="width: auto;height: 120px;" src="${event.target.result}">`;
        this.content = `<img style="width: auto;height: 120px;" src="${event.target.result}">`;


        let notesDom = document.getElementById("preview");
        if (window.getSelection) {
          // 兼容 IE11 10 9 ff safari
          // notesDom.focus(); // 解决ff不获取焦点无法定位问题
          let range = window.getSelection(); // 创建range
          range.selectAllChildren(notesDom); // range 选择notesDom下所有子内容
          range.collapseToEnd(); // 光标移至最后
        } else if (document.selection) {
          // 兼容 IE10 9 8 7 6
          let range = document.selection.createRange(); // 创建选择对象
          range.moveToElementText(notesDom); // range定位到notesDom
          range.collapse(false); // 光标移至最后
          range.select();
        }


      };
      reader.readAsDataURL(file);
      this.file = file;

    },
    //上传文件成功后回调
    uploadPlans() {
      let file = this.file;
      if (!file) {
        this.$message.error("请粘贴图片后上传");
        return;
      }
      this.loading = true;
      let form = new FormData();
      form.append("file", file);
      form.append("type", this.type);
      //uploadCertificate是封装的axios请求，自己根据需求传参


      upload({
        list_id: _this.selectedChat.list_id,
        _token: localStorage.getItem('token'),
        file: form
      }).then(res => {
        console.log("res上传返回", res);
        _this.$refs.upload_file.value = '';
        if (res.err == 0) {
          let msg = {
            content: {
              url: res.data.save_name,
              h: this.height,
              w: this.width,
              save_pic_path: ''
            },
            type: 2
          }
          console.log(this.content); return;
          textMsg({
            list_id: _this.selectedChat.list_id,
            _token: localStorage.getItem('token'),
            content_type: 2,
            content: JSON.stringify({
              url: res.data.save_name,
              h: this.height,
              w: this.width,
              save_pic_path: '',
              type: 2
            }),
            _agent_id: 1
          }).then(res => {
            _this.$store.dispatch('selectSession', _this.selectId)
          })
          setListTime({
            list_id: _this.selectedChat.list_id,
            _token: localStorage.getItem('token'),
            _agent_id: 1
          })


          _this.$store.dispatch('sendMessage', msg)
        }

      })





      // uploadCertificate(form)
      //   .then(data => {
      //     if (data.data && data.data.success) {
      //       this.certificate_pic = data.data.data.source;
      //       this.$message.success(this.name + "上传成功！");
      //     } else {
      //       this.$message.error(this.name + "上传失败！");
      //     }
      //   }).catch(() => {});
    },



  }
}
</script>

<style lang="stylus" scoped>
.text
  position relative
  height 200px
  background #f3f3f3
  textarea
    background #f5f5f5
  .emoji
    position relative
    width 100%
    height 40px
    line-height 40px
    font-size 12px
    padding 0 10px
    box-sizing border-box
    color #7c7c7c
    .iconfont{
      font-size 20px
    }
    .icon-look
      cursor pointer
      &:hover
        color #007AFF
    .emojiBox
      position absolute
      display flex
      flex-wrap wrap
      top -210px
      left -100px
      width 300px
      height 200px
      padding 5px
      background-color #fff
      border 1px solid #d1d1d1
      border-radius 2px
      box-shadow 0 1px 2px 1px #d1d1d1
      &.showbox-enter-active, &.showbox-leave-active
        transition all 0.5s
      &.showbox-enter, &.showbox-leave-active
        opacity 0
      overflow-y auto
  .upload-file
    position relative
    width 20px
    height 16px
    padding-left 20px
    top 3px
    margin-left 10px
    box-sizing border-box
    cursor pointer
  .upload-file:before
    position absolute
    content ''
    width 20px
    height 16px
    top 0
    left 0
    background url('../../assets/images/img.png') no-repeat
    background-size 100% 100%
    display block
    z-index 666
    opacity 1
    pointer-events none
  .upload-video
    position relative
    width 22px
    height 19px
    padding-left 22px
    top 2px
    margin-left 10px
    box-sizing border-box
    cursor pointer
  .upload-video:before
    position absolute
    content ''
    width 22px
    height 19px
    top 0
    left 0
    background url('../../assets/images/video.png') no-repeat
    background-size 100% 100%
    display block
    z-index 666
    opacity 1
    pointer-events none
  .click-icon
    position relative
    width 20px
    height 20px
    top 4px
    margin-left 10px
    background url('../../assets/images/click.png') no-repeat
    background-size 100% 100%
    display inline-block
    cursor pointer
    &:hover
      color #007AFF
  textarea
    box-sizing border-box
    padding 0 30px
    height 110px
    width 100%
    border none
    outline none
    font-family 'Micrsofot Yahei'
    resize none
  .box
    padding-left 10px
  .preview:focus
    border none
    outline none
  .send
    position absolute
    cursor pointer
    bottom 10px
    right 330px
    width 75px
    height 28px
    line-height 28px
    box-sizing border-box
    text-align center
    border 1px solid #e5e5e5
    border-radius 3px
    background #007AFF
    font-size 14px
    color #fff
    &:hover
      background #2975c4
      color #fff
  .warn
    position absolute
    bottom 50px
    right 10px
    width 110px
    height 30px
    line-height 30px
    font-size 12px
    text-align center
    border 1px solid #bdbdbd
    border-radius 4px
    box-shadow 0 1px 5px 1px #bdbdbd
    &.appear-enter-active, &.appear-leave-active
      transition all 1s
    &.appear-enter, &.appear-leave-active
      opacity 0
    &:before
      content ' '
      position absolute
      top 100%
      right 20px
      border 7px solid transparent
      border-top-color #fff
      filter drop-shadow(1px 3px 2px #bdbdbd)
	  
</style>
