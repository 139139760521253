<template>
  <div class="content">
    <div class="msglist">
      <search></search>
      <chatlist></chatlist>
    </div>
    <div class="chatbox">
      <message @selText="selText"></message>
      <v-text ref="cvcc"></v-text>
    </div>
  </div>
</template>

<script>
import search from "@/components/search/search";
import chatlist from "@/components/chatlist/chatlist";
import message from "@/components/message/message";
import vText from "@/components/text/text";
export default {
  name: "chat",
  components: {
    search,
    chatlist,
    message,
    vText,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    selText(e) {
      this.$refs.cvcc.$refs.text.innerHTML = e;
      this.$refs.cvcc.content = e;
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  display: flex;
  .msglist {
    width: 250px;
    background: #fff;
  }
  .chatbox {
    border-left: 1px solid #eee;
    flex: 1;
  }
}
</style>
